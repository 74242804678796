import { Pipe, PipeTransform } from '@angular/core';
import { PLAN_MULTI_EXCLU_PREFIX, PLAN_SCI_EXCLUSIVITE, SubscriptionPlan } from '@dougs/subscription/dto';

@Pipe({
  standalone: true,
  name: 'planSelectionDetailsComputePlanName',
})
export class PlanSelectionDetailsComputePlanNamePipe implements PipeTransform {
  transform(plan: SubscriptionPlan): string {
    if (plan.id.startsWith(PLAN_MULTI_EXCLU_PREFIX)) {
      return 'Multi-Société';
    } else if (plan.id === PLAN_SCI_EXCLUSIVITE) {
      return plan.name;
    }

    return plan.group;
  }
}
